<template>
  <BaseCard class="label-button-card" :base-card="baseCard" :style="style">
    <FormulateInput
      v-if="searchable"
      type="search"
      name="search"
      class="label-button-card__search custom-search-left"
      :placeholder="searchPlaceholder"
      v-model="search"
      @input="searchQuery"
    />
    <div class="label-button-card__header" v-if="headerTitle">
      {{ headerTitle }}
    </div>
    <div class="label-button-card__body">
      <template v-if="innerList.length">
        <LabelButtonItem
          v-for="(item, idx) in innerList"
          :labels="labels"
          :displayReviewStatus="displayReviewStatus"
          :key="idx"
          :data="item"
          :settings="settings"
        />
      </template>
      <h6 v-else-if="noData">{{ noData }}</h6>
    </div>
    <Button
      v-if="button"
      class="label-button-card__button"
      :bgColor="button.bgColor"
      :fontWeight="button.fontWeight"
      :padding="button.padding"
      :label="button.label"
      @click="button.action"
      variant="btn-purple"
    />
  </BaseCard>
</template>

<script>
import LabelButtonItem from "@/components/Cards/LabelButtonItem";
import BaseCard from "@/components/Cards/BaseCard";
import Button from "@/components/Buttons/Button";

export default {
  name: "LabelButtonCard",
  components: {
    LabelButtonItem,
    BaseCard,
    Button,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    displayReviewStatus: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      default: "",
    },
    headerTitle: {
      type: String,
      default: "",
    },
    baseCard: {
      type: Boolean,
      default: true,
    },
    noData: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "initial",
    },
    maxHeight: {
      type: String,
      default: "initial",
    },
    listHeight: {
      type: String,
      default: "initial",
    },
    listMinHeight: {
      type: String,
      default: "initial",
    },
    listMaxHeight: {
      type: String,
      default: "initial",
    },
    settings: {
      type: Object,
      default: () => {},
    },
    button: {
      type: Object,
      default: () => {},
    },
    labels: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      search: "",
      innerList: this.list,
    };
  },
  computed: {
    style() {
      return {
        "--height": this.height,
        "--max-height": this.maxHeight,
        "--list-height": this.listHeight,
        "--list-min-height": this.listMinHeight,
        "--list-max-height": this.listMaxHeight,
      };
    },
  },
  methods: {
    searchQuery() {
      this.innerList = this.list.filter((item) => {
        return item.label.match(this.search);
      });
    },
  },
  mounted() {
    this.innerList = this.list;
  },
  watch: {
    list: {
      deep: true,
      handler() {
        this.innerList = this.list;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.label-button-card {
  display: flex;
  flex-direction: column;
  height: var(--height);
  max-height: var(--max-height);
  padding: 10px 0;

  &__search {
    padding: 25px;
    margin-bottom: 0;
  }

  &__header {
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    height: 60px;
    line-height: 60px;
    margin-top: -10px;
    border-bottom: 1px solid $light_gray;
    color: $dark;
    padding-left: 25px;
    padding-right: 25px;
  }

  &__body {
    height: var(--list-height);
    min-height: var(--list-min-height);
    max-height: var(--list-max-height);
    overflow: auto;
    @include transition--ease-out;

    &::-webkit-scrollbar {
      width: $scrollbar_width;
      height: $scrollbar_height;
    }

    &::-webkit-scrollbar-track {
      background: $light_gray;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $focus_color;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
    }

    h6 {
      text-align: center;
      padding: 15px 15px 0;
    }
  }
  &__button {
    width: max-content;
    margin: 25px auto 0;
    border-radius: 5px;
    //background-color: $purple;
    //color: $white;
  }
}
</style>
